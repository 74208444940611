<template>
  <div class="row">
    <!-- begin::Overview -->
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-header flex-wrap">
          <div class="card-title">
            <h3 class="card-label">
              Overview
              <span class="text-muted pt-2 font-size-sm d-block">All modules</span>
            </h3>
          </div>
        </div>

        <div class="card-body">
          <!-- begin::Datatable actions -->
          <div class="mb-7">
            <div class="row align-items-center">
              <div class="col-12 col-lg">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    @keyup.enter="$refs.datatable.search"
                    @keyup="searchDebounced"
                  >
                  <span>
                    <i>
                      <font-awesome-icon :icon="['fas', 'search']" />
                    </i>
                  </span>
                </div>
              </div>

              <div class="col-12 mt-3 col-lg-auto mt-lg-0">
                <button type="button" class="btn btn-block btn-outline-secondary" style="min-width: 150px;">
                  <i class="text-brand">
                    <font-awesome-icon :icon="['fas', 'filter']" />
                  </i>
                  Filter
                </button>
              </div>
            </div>
          </div>
          <!-- end::Datatable actions -->

          <!-- begin::Datatable -->
          <datatable
            ref="datatable"
            :url="datatable.url"
            :fields="datatable.fields"
            :search-fields="datatable.searchFields"
            :on-pagination-data="onPaginationData"

            :transform="transform"
          >
            <!-- Serial -->
            <div
              slot="serial"
              slot-scope="props"
              class="sunbed-serial"
            >
              <router-link
                class="text-info"
                :to="`/admin/modules/view/${props.rowData._meta.guid}`"
              >
                {{ props.rowData.serial }}
              </router-link>
            </div>

            <!-- Sunbed link -->
            <div
              slot="sunbed"
              slot-scope="props"
              class="w-200px"
            >
              <router-link
                v-if="props.rowData.equipment_guid"
                :to="`/sunbeds/view/${props.rowData.equipment_guid}`"
                class="label w-100 label-inline label-xl label-rounded label-light-info p-5"
              >
                <u>Linked</u>
              </router-link>

              <span v-else class="label w-100 label-inline label-xl label-rounded label-light-warning p-5">
                Not linked
              </span>
            </div>

            <!-- Software -->
            <div
              slot="software"
              slot-scope="props"
              style="width:200px;"
            >
              <span class="label w-100 label-inline label-xl label-rounded label-light-primary p-5">
                {{ props.rowData.software }}
              </span>
            </div>

            <!-- Balena UUID -->
            <div
              slot="balena_uuid"
              slot-scope="props"
              class="d-flex"
            >
              <template v-if="props.rowData.balena.uuid">
                <span class="label label-inline label-xl label-rounded label-light-info p-5 w-100">
                  {{ (props.rowData.balena.uuid ? props.rowData.balena.uuid.substr(0, 7) : '') }}
                </span>

                <i class="pl-2 cursor-pointer text-hover-info my-auto" @click.prevent="copyText(props.rowData.balena.uuid)">
                  <font-awesome-icon :icon="['fas', 'copy']" />
                </i>
              </template>

              <template v-else>
                <span class="label label-inline label-xl label-rounded label-secondary p-5 w-100">
                  -
                </span>
              </template>
            </div>
          </datatable>
          <!-- end::Datatable -->

          <!-- begin::Datatable footer -->
          <div class="datatable datatable-default">
            <div class="datatable-pager datatable-paging-loaded">
              <datatable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              />

              <datatable-pagination-info
                ref="pagination.info"
                @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              />
            </div>
          </div>
          <!-- end::Datatable footer -->
        </div>
      </div>
    </div>
    <!-- end::Overview -->
  </div>
</template>

<script>
// Mixins
import { DatatableMixin } from '@vedicium/metronic-vue';

// Libraries
import moment from 'moment-timezone';
import Device from '@/libs/classes/device';

export default {
  mixins: [DatatableMixin],
  data () {
    return {
      datatable: {
        url: Device.uri,

        fields: [{
          name: 'serialnumber',
          title: '#',
          sortField: 'serialnumber',
          titleClass: 'datatable-cell-center index',
          dataClass: 'datatable-cell-center index',
        }, {
          name: 'serial',
          title: 'Serial',
          sortField: 'serial',
          dataClass: 'string',
        }, {
          name: 'sunbed',
          title: 'Sunbed',
          dataClass: 'string',
        }, {
          name: 'software',
          title: 'Software',
          dataClass: 'string',
        }, {
          name: 'access_point',
          title: 'Access Point',
          dataClass: 'string',
          formatter (accessPoint) {
            return `${accessPoint.ssid} / ${accessPoint.psk}`;
          },
        }, {
          name: 'type',
          title: 'Type',
          dataClass: 'string',
          sortField: 'type',
        }, {
          name: 'hardware.model',
          title: 'Hardware',
          dataClass: 'string',
          sortField: 'hardware.model',
        }, {
          name: 'balena_uuid',
          title: 'Balena',
          dataClass: 'string',
          sortField: 'balena.uuid',
        }, {
          name: 'last_ping',
          title: 'Last ping',
          dataClass: 'string',
          sortField: 'last_ping',
          formatter (value) {
            return (value ? moment(value).fromNow() : 'Never');
          },
        }, {
          name: '_meta.created',
          title: 'Register date',
          titleClass: 'string',
          dataClass: 'string',
          sortField: '_meta.created',
          formatter (value) {
            return moment(value).locale('en-gb').format('LLL');
          },
        }],
        searchFields: ['serial', 'access_point.ssid', 'access_point.psk', 'balena.uuid'],
      },
    };
  },
  mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Admin' }, { title: 'Modules' }]);
  },
  methods: {
    transform (data) {
      const response = { ...data };
      if (response.data && Array.isArray(response.data)) {
        response.data = response.data.map((row) => new Device().merge(row));
      }
      return response;
    },

    copyText (text = null) {
      if (!text) {
        return;
      }

      navigator.clipboard.writeText(text);
    },
  },
};
</script>
